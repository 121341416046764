const languages = [
  {
    label: '---',
    value: '',
  },
  {
    value: 'fr',
    label: 'French',
  },
  {
    value: 'zh',
    label: 'Chinese',
  },
]

export default languages
