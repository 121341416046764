import { fetch } from './Fetch'
import Environments from '../_constants/Environment'

const { API_HOST } = Environments

const getTranslation = (data, headers) => {
  return fetch('get', `${API_HOST}/translations/api/v1/translations`, {}, headers, data)
}

const updateTranslation = (data, headers) => {
  return fetch('post', `${API_HOST}/translations/api/v1/translations`, data, headers)
}

export const TranslationService = {
  getTranslation,
  updateTranslation,
}
