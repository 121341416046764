const CONSIST_TYPES = {
  still: 1,
  sparking: 2,
}
const CONSIST_REVERSED = {
  1: 'still',
  2: 'sparking',
}

export const getWineConsist = (value) => CONSIST_TYPES[value] || 0
export const getWineConsistReversed = (value) => CONSIST_REVERSED[value] || 0
