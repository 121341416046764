import { actionTypes } from '../_actions/translations.action'

const initialState = {
  translations: [],
  language: '',
}

const translation = (state = initialState, { type, payload } = {}) => {
  switch (type) {
    case actionTypes.SAVE_ALL_TRANSLATIONS:
      return { ...state, translations: payload.translations, language: payload.language }

    default:
      return state
  }
}

export default translation
