import { startLoading, stopLoading } from './loading.action'
import { toast } from '../../components/Toast/Toast'
import { NftService } from '../../Services/nft.service'

export const actionTypes = {
  SAVE_ALL_NFTS: 'SAVE_ALL_NFTS',
}

export function saveMyAllNfts(data) {
  return {
    type: actionTypes.SAVE_ALL_NFTS,
    payload: data,
  }
}

export function getAllNftList(data) {
  return (dispatch, getState) =>
    new Promise((resolve, reject) => {
      dispatch(startLoading())
      NftService.getAllNftList(data, {
        Authorization: localStorage.getItem('authorizations'),
      })
        .then((res) => {
          dispatch(saveMyAllNfts(res.data))
          dispatch(stopLoading())
          resolve(res)
        })
        .catch((ex) => {
          toast.error(ex?.message)
          dispatch(stopLoading())
          reject(ex)
        })
    })
}

export function createNft(data) {
  return (dispatch, getState) =>
    new Promise((resolve, reject) => {
      dispatch(startLoading())
      NftService.createNft(data, {
        Authorization: localStorage.getItem('authorizations'),
      })
        .then((res) => {
          toast.success(res?.data?.message)

          dispatch(stopLoading())

          resolve(res)
        })
        .catch((ex) => {
          toast.error(ex.data.message)

          dispatch(stopLoading())
          reject(ex)
        })
    })
}
export function bulkCreateNfts(data) {
  return (dispatch, getState) =>
    new Promise((resolve, reject) => {
      dispatch(startLoading())
      NftService.bulkCreateNfts(data, {
        Authorization: localStorage.getItem('authorizations'),
      })
        .then((res) => {
          // toast.success(res?.data?.message)

          dispatch(stopLoading())

          resolve(res)
        })
        .catch((ex) => {
          // toast.error(ex.data.message)

          dispatch(stopLoading())
          reject(ex)
        })
    })
}

export function bulkUpdateNfts(data) {
  return (dispatch, getState) =>
    new Promise((resolve, reject) => {
      dispatch(startLoading())
      NftService.bulkUpdateNfts(data, {
        Authorization: localStorage.getItem('authorizations'),
      })
        .then((res) => {
          // toast.success(res?.data?.message)

          dispatch(stopLoading())

          resolve(res)
        })
        .catch((ex) => {
          // toast.error(ex.data.message)

          dispatch(stopLoading())
          reject(ex)
        })
    })
}

export function getNftDetails(data) {
  return (dispatch, getState) =>
    new Promise((resolve, reject) => {
      dispatch(startLoading())
      NftService.getNftDetails(data, {
        Authorization: localStorage.getItem('authorizations'),
      })
        .then((res) => {
          dispatch(stopLoading())

          resolve(res)
        })
        .catch((ex) => {
          dispatch(stopLoading())
          reject(ex)
        })
    })
}

export function updateNftDetails(data) {
  return (dispatch, getState) =>
    new Promise((resolve, reject) => {
      dispatch(startLoading())
      NftService.updateNftDetails(data, {
        Authorization: localStorage.getItem('authorizations'),
      })
        .then((res) => {
          dispatch(stopLoading())
          resolve(res)
        })
        .catch((ex) => {
          dispatch(stopLoading())
          reject(ex)
        })
    })
}

export function sendToApproveNft(data) {
  return (dispatch, getState) =>
    new Promise((resolve, reject) => {
      dispatch(startLoading())
      NftService.sendToApproveNft(data, {
        Authorization: localStorage.getItem('authorizations'),
      })
        .then((res) => {
          dispatch(stopLoading())
          resolve(res)
        })
        .catch((ex) => {
          dispatch(stopLoading())
          reject(ex)
        })
    })
}

export function deleteDrop(data) {
  return (dispatch, getState) =>
    new Promise((resolve, reject) => {
      dispatch(startLoading())
      NftService.deleteDrop(data, {
        Authorization: localStorage.getItem('authorizations'),
      })
        .then((res) => {
          toast.success(res?.data?.message)
          dispatch(stopLoading())
          resolve(res)
        })
        .catch((ex) => {
          toast.error(ex.data.message)
          dispatch(stopLoading())
          reject(ex)
        })
    })
}

export function draftNftPrev(data) {
  return (dispatch, getState) =>
    new Promise((resolve, reject) => {
      dispatch(startLoading())
      NftService.draftNftPrev(data, {
        Authorization: localStorage.getItem('authorizations'),
      })
        .then((res) => {
          toast.success(res?.data?.message)
          dispatch(stopLoading())
          resolve(res)
        })
        .catch((ex) => {
          toast.error(ex.data.message)
          dispatch(stopLoading())
          reject(ex)
        })
    })
}

export function saveDropLogs(data) {
  return (dispatch, getState) =>
    new Promise((resolve, reject) => {
      dispatch(startLoading())
      NftService.saveDropLogs(data, {
        Authorization: localStorage.getItem('authorizations'),
      })
        .then((res) => {
          dispatch(stopLoading())
          resolve(res)
        })
        .catch((ex) => {
          toast.error(ex.data.message)
          dispatch(stopLoading())
          reject(ex)
        })
    })
}

export function getDropLogs(data) {
  return (dispatch, getState) =>
    new Promise((resolve, reject) => {
      dispatch(startLoading())
      NftService.getDropLogs(data, {
        Authorization: localStorage.getItem('authorizations'),
      })
        .then((res) => {
          dispatch(stopLoading())
          resolve(res)
        })
        .catch((ex) => {
          toast.error(ex.data.message)
          dispatch(stopLoading())
          reject(ex)
        })
    })
}

export function transferNft(data) {
  return (dispatch, getState) =>
    new Promise((resolve, reject) => {
      dispatch(startLoading())
      NftService.transferNft(data, {
        Authorization: localStorage.getItem('authorizations'),
      })
        .then((res) => {
          dispatch(stopLoading())
          resolve(res)
        })
        .catch((ex) => {
          toast.error(ex.data.message)
          dispatch(stopLoading())
          reject(ex)
        })
    })
}

export function getTransferNftData(data) {
  return (dispatch, getState) =>
    new Promise((resolve, reject) => {
      dispatch(startLoading())
      NftService.getTransferNftData(data, {
        Authorization: localStorage.getItem('authorizations'),
      })
        .then((res) => {
          dispatch(stopLoading())
          resolve(res)
        })
        .catch((ex) => {
          toast.error(ex.data.message)
          dispatch(stopLoading())
          reject(ex)
        })
    })
}
export function updateNftWineryPerksTextContentId(data) {
  return (dispatch, getState) =>
    new Promise((resolve, reject) => {
      dispatch(startLoading())
      NftService.updateNftWineryPerksTextContentId(data, {
        Authorization: localStorage.getItem('authorizations'),
      })
        .then((res) => {
          dispatch(stopLoading())
          resolve(res)
        })
        .catch((ex) => {
          toast.error(ex.data.message)
          dispatch(stopLoading())
          reject(ex)
        })
    })
}

export function translateNftAutomatically(data) {
  return (dispatch, getState) =>
    new Promise((resolve, reject) => {
      dispatch(startLoading())
      NftService.translateNftAutomatically(data, {
        Authorization: localStorage.getItem('authorizations'),
      })
        .then((res) => {
          dispatch(stopLoading())
          resolve(res)
        })
        .catch((ex) => {
          toast.error(ex.data.message)
          dispatch(stopLoading())
          reject(ex)
        })
    })
}
