import { useMemo, useState } from 'react'
import cn from 'classnames'
import DropdownField from 'components/common/DropdownField/DropdownField'
import { useDispatch, useSelector } from 'react-redux'
import { keyBy } from 'lodash'

import { getAllTranslations, updateTranslations } from 'redux/_actions/translations.action'
import WineftForm from '../WineftForm'

import comCSS from './WineftTranslation.module.scss'
import { ButtonCustom } from 'components/button/ButtonCustom'
import { updateNftWineryPerksTextContentId } from 'redux/_actions/nft.action'
import languages from '_constants/languages'

const translationsKeysData = [
  {
    key: 'nft_subtitle',
    keyTextContent: 'nft_subtitle_text_content_id',
  },
  {
    key: 'winery_perks',
    keyTextContent: 'winery_perks_text_content_id',
  },
]
const translationsKeysDataNft = [
  {
    key: 'winery_perks',
    keyTextContent: 'winery_perks_text_content_id',
  },
]

const WineftTranslation = ({
  isEmptyDrop,
  setIsEmptyDrop,
  nftDetails,
  bottleDetails,
  dropDetails,
  start_drop_event_date,
  finish_drop_event_date,
  setFinish_drop_event_date,
  bottle_release_dates,
  allcountryList,
  onOpen,
  getTotalSelectedCount,
  merchants,
  loadNftDetails,
  isTranslationMode,
  setIsTranslationMode,
}) => {
  const dispatch = useDispatch()
  const { translations } = useSelector((state) => state?.translation)
  const [language, setLanguage] = useState('')

  const nftDetailsData = useMemo(() => {
    const drop = { ...nftDetails }
    const translationsMap = keyBy(translations, 'text_content_id')
    translationsKeysData.forEach((el) => {
      drop[el.key] = translationsMap[nftDetails[el.keyTextContent]]?.translation || ''
    })
    translationsKeysDataNft.forEach((el) => {
      drop[el.key] = translationsMap[nftDetails[el.keyTextContent]]?.translation || ''
    })

    return drop
  }, [nftDetails, translations])

  const getTranslationsIds = () => {
    const result = translationsKeysData
      .map((el) => nftDetails[el.keyTextContent])
      .filter((el) => el)

    const nftsResult = translationsKeysDataNft
      .map((el) => nftDetails[el.keyTextContent])
      .filter((el) => el)

    return result.concat(nftsResult)
  }
  const loadTranslations = (language_id) => {
    const ids = getTranslationsIds().join(',')
    dispatch(getAllTranslations({ ids, language_id }))
  }

  const onLanguageSelect = (e) => {
    setLanguage(e.target.value)
    loadTranslations(e.target.value)
  }

  const getTranslationsToUpdate = (keysData, values) => {
    return keysData
      .map((el) => ({
        ...el,
        value: nftDetails[el.key] || null, // original value in en
        translation: values[el.key] || null, // e.g. values.reviews
        text_content_id: nftDetails[el.keyTextContent] || null,
      }))
      .filter((el) => el.text_content_id !== null || el.translation !== null)
  }

  const updateWineryPerks = async (values) => {
    const drop = await loadNftDetails()
    if (drop.winery_perks_text_content_id) {
      dispatch(
        updateNftWineryPerksTextContentId({
          drop_id: drop.drop_id,
          winery_perks_text_content_id: drop.winery_perks_text_content_id,
        }),
      )
    }
  }

  const saveTranslations = (values) => {
    const data = {
      model: 'drops',
      primaryKey: 'drop_id',
      primaryKeyValue: nftDetails.drop_id,
      translations: getTranslationsToUpdate(translationsKeysData, values),
      language_id: language,
    }

    dispatch(updateTranslations(data, () => updateWineryPerks(values)))
  }

  return (
    <div className={cn(comCSS.wrapper)}>
      <div className={comCSS.languageSelectorWrapper}>
        <DropdownField
          onChange={onLanguageSelect}
          options={languages}
          value={language}
          label='Choose language'
          className={comCSS.languageSelector}
        />
        {isTranslationMode && (
          <ButtonCustom onClick={() => setIsTranslationMode(false)} title={'Close Translation'} />
        )}
      </div>
      {language && language !== '---' && (
        <WineftForm
          isEmptyDrop={isEmptyDrop}
          setIsEmptyDrop={setIsEmptyDrop}
          nftDetails={nftDetailsData}
          bottleDetails={bottleDetails}
          dropDetails={dropDetails}
          start_drop_event_date={start_drop_event_date}
          finish_drop_event_date={finish_drop_event_date}
          setFinish_drop_event_date={setFinish_drop_event_date}
          bottle_release_dates={bottle_release_dates}
          allcountryList={allcountryList}
          onOpen={onOpen}
          getTotalSelectedCount={getTotalSelectedCount}
          merchants={merchants}
          sendNftToApprove={() => {}}
          onSubmit={saveTranslations}
          isTranslationMode={true}
          hideApproveBtn={true}
        />
      )}
    </div>
  )
}

WineftTranslation.propTypes = {}

export default WineftTranslation
