import { useRef } from 'react'
import { Col, Row } from 'antd'
import cn from 'classnames'
import { toast } from '../../../../components/Toast/Toast.js'
import { useDispatch } from 'react-redux'
import InputField from '../../../../components/common/InputField/InputField'
import { Form } from 'semantic-ui-react'
import { useFormik } from 'formik'
import { WinerMakerAction } from '../../../../redux/_actions'
import { editBottleSchema } from '../schema'
import 'antd/dist/antd.min.css'
import '../../CreateWinemakers/CreateWinemakers.scss'
import '../CreateBottle/CreateBottle.scss'
import './EditBottle.scss'
import { IconDelete } from '../../../../Assets/image/svgimages/sVGImg'
import RadioButton from '../../../../components/common/RadioButton/RadioButton'
import WineBottleName from '../components/WineBottleName'
import Notes from '../components/Notes'
import { getWineConsist, getWineConsistReversed } from '../getWineConsist'

const BottleForm = ({
  bottleId,
  bottleDetail,
  onSubmit, // updateBottleDetails
  handlefilterOnWinerySelect: handlefilterOnWinerySelectFn,
  wineryList,
  handleCountryChange: handleCountryChangeFn,
  allCountry,
  regionList,
  appellationWithRegion,
  colorList,
  sweetNessList,
  stillList,
  sparklingList,
  handleSizeChange: handleSizeChangeFn,
  sizeList,
  isTranslationMode,
}) => {
  const dispatch = useDispatch()
  const inputRefBottle = useRef()
  const { uploadFileGc } = WinerMakerAction

  const bottleFormik = useFormik({
    enableReinitialize: true,
    validationSchema: editBottleSchema,
    initialValues: {
      winery_name: bottleDetail?.winery_name,
      brand_name: bottleDetail?.brand_name,
      bottle_image: bottleDetail?.bottle_image,
      wine_name: bottleDetail?.wine_name,
      country: bottleDetail?.country,
      region_id: bottleDetail?.region_id,
      appellation: bottleDetail?.appellation || '',
      wine_color_id: bottleDetail?.wine_color_id,
      size_id: bottleDetail?.size_id,
      badge: bottleDetail?.badge || '',
      description: bottleDetail?.description,
      wine_spectator_point: bottleDetail?.wine_spectator_point,
      wine_advocate_point: bottleDetail?.wine_advocate_point,
      wine_falstaff_point: bottleDetail?.wine_falstaff_point,
      wine_vinous_point: bottleDetail?.wine_vinous_point,
      wine_james_suckling_point: bottleDetail?.wine_james_suckling_point,
      wine_burgbound_point: bottleDetail?.wine_burgbound_point,
      sweetness_wine_id: bottleDetail?.wine_sweetness?.id,
      grape: bottleDetail?.grape || '',
      vineyard_name: bottleDetail?.vineyard_name,
      classification: bottleDetail?.classification,
      consist: `${getWineConsistReversed(bottleDetail?.consist)}`,
      ageability: bottleDetail?.ageability || '',
      rarity: bottleDetail?.rarity || '',
      bottle_image_title: bottleDetail?.bottle_image_title || '',
      bottle_image_alt: bottleDetail?.bottle_image_alt || '',
      strength: bottleDetail?.strength || '',
      tannins: bottleDetail?.tannins || '',
      texture: bottleDetail?.texture || '',
      review: bottleDetail?.review || '',
      about_winery: bottleDetail?.about_winery || '',
      terroir_and_aging_method: bottleDetail?.terroir_and_aging_method || '',
      tasting_notes: bottleDetail?.tasting_notes || '',
      pairing: bottleDetail?.pairing || '',
    },
    onSubmit: (values) => {
      values['user_id'] = bottleDetail.user_id
      values['id'] = bottleId
      values['consist'] = getWineConsist(values?.consist)
      values['rarity'] = `${values.rarity}`
      values['strength'] = `${values.strength}`
      values['tannins'] = `${values.tannins}`
      values['texture'] = `${values.texture}`
      onSubmit(values)
      if (!values['appellation']) {
        delete values['appellation']
      }
    },
  })

  const handlefilterOnWinerySelect = (targetValue) => {
    const selectedWinery = handlefilterOnWinerySelectFn(targetValue)
    bottleFormik.setFieldValue('country', selectedWinery?.country)
    bottleFormik.setFieldValue('region_id', selectedWinery.region_obj?.id)
    bottleFormik.setFieldValue('appellation', selectedWinery.appellation_obj?.appellation?.id)
  }

  const handleCountryChange = (e, field) => {
    bottleFormik.setFieldValue(field, e.target.value)
    if (field === 'country') {
      bottleFormik.setFieldValue('region_id', '')
      bottleFormik.setFieldValue('appellation', '')
    }
    if (field === 'region_id') {
      bottleFormik.setFieldValue('appellation', '')
    }
    handleCountryChangeFn(e, field, bottleFormik.values.country)
  }

  const handleSizeChange = (e, field) => {
    bottleFormik.setFieldValue(field, e.target.value)
    handleSizeChangeFn(e)
  }

  const badgeSelectList = ['Icon', 'Rising Star', 'Hidden Gem']

  const handleBadgeChange = (e, field) => {
    bottleFormik.setFieldValue(field, e.target.value)
    // badgeSelectList.forEach((element) => {
    //   if (element.id === e.target.value) {
    //     setSizeText(element.size)
    //   }
    // })
  }

  const uploadImageApiImage = (file) => {
    let formData = new FormData()
    formData.append('folderName', 'testing')
    formData.append('file', file)
    dispatch(uploadFileGc(formData)).then((res) => {
      bottleFormik.setFieldValue('bottle_image', res?.data.data[0]?.url)
    })
  }
  const isImage = (icon) => {
    const ext = ['.jpg', '.jpeg', '.png', '.JPG', '.JPEG', '.PNG', '.SVG', '.svg']
    return ext.some((el) => icon.endsWith(el))
  }

  const uploadFileToS3Image = async (input) => {
    input.persist()

    let file = input.target.files[0]
    let fname = file.name

    if (!isImage(fname)) {
      toast.error('File extension not supported!')
      input.target.value = null
    } else {
      uploadImageApiImage(file)
    }
  }

  const deleteBottleImage = () => {
    bottleFormik.setFieldValue('bottle_image', '')
    if (inputRefBottle.current !== undefined) {
      inputRefBottle.current.value = ''
    }
  }

  const handleChange = (value) => {
    bottleFormik.setFieldValue('consist', value)
  }
  return (
    <Form onSubmit={bottleFormik.handleSubmit}>
      <div className='block' id='a'>
        <div className={cn({ invisible: isTranslationMode })}>
          <WineBottleName bottleFormik={bottleFormik} />
        </div>

        <div className={cn('genBottle_Id inputField__wrap ', { invisible: isTranslationMode })}>
          <Row gutter={20} className='w-100 bottle-files-handle'>
            <Col md={12}>
              <div className='inputField__label file-input__image'>
                <div className='file-input__image-subheader'>
                  Image of the bottle<sup>*</sup>
                </div>
                <p>File types supported: JPG, PNG, GIF, SVG.</p>
                <p>Max size: 5 MB , 1080x1920</p>
              </div>
              <div className='uploadField'>
                <div className='profilePic'>
                  <div className='profilePic-inner'>
                    <input
                      type='file'
                      name='profile_image'
                      id=' profile_image'
                      className='input__input'
                      onChange={uploadFileToS3Image}
                      formEncType='multipart/form-data'
                      ref={inputRefBottle}
                      disabled={bottleFormik.values.bottle_image !== ''}
                    />
                    <label
                      className={cn('file-input__prof', {
                        'editImage IconHidd': bottleFormik.values.bottle_image !== '',
                      })}
                      htmlFor='file-input'
                    >
                      <img
                        style={{ width: 64, height: 64 }}
                        src={bottleFormik.values.bottle_image}
                        alt=''
                      />
                    </label>
                    {bottleFormik.touched.bottle_image && (
                      <p
                        style={{
                          color: 'red',
                          fontSize: '13px',
                          margin: 'auto',
                          textAlign: 'center',
                          position: 'absolute',
                          marginTop: '5px',
                          width: '100%',
                        }}
                      >
                        {bottleFormik.errors.bottle_image}
                      </p>
                    )}
                  </div>
                </div>

                {bottleFormik.values.bottle_image && (
                  <div className='editand_DltBtn'>
                    <button
                      type='button'
                      onClick={() => deleteBottleImage()}
                      className='DeltBtnsIcon'
                    >
                      <IconDelete />
                    </button>
                  </div>
                )}
              </div>
            </Col>
          </Row>
        </div>
        <div>
          <InputField
            name='bottle_image_title'
            label='Image Title (attribute title)'
            placeholder='Wine name...'
            id='bottle_image_title'
            onChange={(event) => {
              bottleFormik.setFieldValue('bottle_image_title', event.target.value)
            }}
            value={bottleFormik.values.bottle_image_title}
            formik={bottleFormik}
          />
        </div>
        <div>
          <InputField
            name='bottle_image_alt'
            label='Image Alt (attribute alt)'
            placeholder='Wine name...'
            id='bottle_image_alt'
            onChange={(event) => {
              bottleFormik.setFieldValue('bottle_image_alt', event.target.value)
            }}
            value={bottleFormik.values.bottle_image_alt}
            formik={bottleFormik}
          />
        </div>
      </div>
      <div className='block' id='b'>
        <div className='Header'>
          <h3 className='common-sub-heading'>2: Details</h3>
          <p>
            The information you provide below are key features that will be displayed on our public
            web page highlighting the features of the labels
          </p>
        </div>

        <div className={cn('cus_drop inputField__wrap', { invisible: isTranslationMode })}>
          <label className='drop_label'>
            Wine Producer<sup>*</sup>
          </label>
          <select
            id='winery_name'
            value={bottleFormik.values.winery_name}
            onChange={(e) => {
              bottleFormik.setFieldValue('winery_name', e.target.value)
              handlefilterOnWinerySelect(e.target.value)
            }}
            disabled={isTranslationMode}
          >
            <option value='' disabled>
              Select producer of the wine
            </option>
            {wineryList.map((option) => (
              <option key={option.userId} value={option.winery_name} name={option.winery_name}>
                {option.winery_name}
              </option>
            ))}
          </select>
          {bottleFormik.touched.winery_name && (
            <p
              style={{
                color: 'red',
                fontSize: '13px',
                marginRight: 'auto',
                marginLeft: '0',
              }}
            >
              {bottleFormik.errors.winery_name}
            </p>
          )}
        </div>

        <div className='cus_drop inputField__wrap'>
          <label className='drop_label'>
            Country<sup>*</sup>
          </label>
          <select
            id='country'
            value={bottleFormik.values.country}
            onChange={(e) => handleCountryChange(e, 'country')}
            disabled={isTranslationMode}
          >
            <option value=''>Select country</option>
            {allCountry.map((option) => (
              <option key={`country-${option}`} value={option}>
                {option}
              </option>
            ))}
          </select>
          {bottleFormik.touched.country && (
            <p
              style={{
                color: 'red',
                fontSize: '13px',
                marginRight: 'auto',
                marginLeft: '0',
              }}
            >
              {bottleFormik.errors.country}
            </p>
          )}
        </div>
        <div className='cus_drop inputField__wrap'>
          <label className='drop_label'>
            Region<sup>*</sup>
          </label>
          <select
            id='region_id'
            value={bottleFormik.values.region_id}
            onChange={(e) => handleCountryChange(e, 'region_id')}
            disabled={isTranslationMode}
          >
            <option value='' disabled>
              Select region
            </option>
            {regionList.map((option) => (
              <option key={`r-${option?.id}`} value={option?.id}>
                {option.region_name}
              </option>
            ))}
          </select>
          {bottleFormik.touched.region_id && (
            <p
              style={{
                color: 'red',
                fontSize: '13px',
                marginRight: 'auto',
                marginLeft: '0',
              }}
            >
              {bottleFormik.errors.region_id}
            </p>
          )}
        </div>
        <div className='cus_drop inputField__wrap'>
          <label className='drop_label'>Appellation</label>
          <select
            id='appellation'
            value={bottleFormik.values.appellation}
            onChange={(e) => handleCountryChange(e, 'appellation')}
            disabled={isTranslationMode}
          >
            <option value='' disabled>
              Select appellation
            </option>
            {appellationWithRegion.map((option, index) => (
              <option key={`apr-${option.id}`} value={option.id}>
                {option.appellation}
              </option>
            ))}
          </select>
          {bottleFormik.touched.appellation && (
            <p
              style={{
                color: 'red',
                fontSize: '13px',
                marginRight: 'auto',
                marginLeft: '0',
              }}
            >
              {bottleFormik.errors.appellation}
            </p>
          )}
        </div>

        <InputField
          name='vineyard_name'
          label='Vineyard name or Cru'
          placeholder='Ex. Jacuzzi Family Vineyards, Chateau Latour'
          id='vineyard_name'
          onChange={(event) => {
            bottleFormik.setFieldValue('vineyard_name', event.target.value)
          }}
          value={bottleFormik.values.vineyard_name}
          formik={bottleFormik}
          className={cn({ invisible: isTranslationMode })}
          disabled={isTranslationMode}
        />
        <InputField
          name='wine_name'
          label='Cuvée'
          placeholder='Les combettles'
          id='wine_name'
          onChange={(event) => {
            bottleFormik.setFieldValue('wine_name', event.target.value)
          }}
          value={bottleFormik.values.wine_name}
          formik={bottleFormik}
          className={cn({ invisible: isTranslationMode })}
        />
        <InputField
          name='grape'
          label='Grape'
          placeholder='Ex. Grape'
          id='grape'
          onChange={(event) => {
            bottleFormik.setFieldValue('grape', event.target.value)
          }}
          value={bottleFormik.values.grape}
          formik={bottleFormik}
        />
        <div className='cus_drop inputField__wrap'>
          <label className='drop_label'>
            Color of the wine<sup>*</sup>
          </label>
          <select
            id='wine_color_id'
            value={bottleFormik.values.wine_color_id}
            onChange={(e) => handleCountryChange(e, 'wine_color_id')}
            disabled={isTranslationMode}
          >
            <option value='' disabled>
              Select wine color
            </option>
            {colorList.map((option) => (
              <option key={`cl-${option.id}`} value={option.id}>
                {option.color_name}
              </option>
            ))}
          </select>
          {bottleFormik.touched.wine_color_id && (
            <p
              style={{
                color: 'red',
                fontSize: '13px',
                marginRight: 'auto',
                marginLeft: '0',
              }}
            >
              {bottleFormik.errors.wine_color_id}
            </p>
          )}
        </div>

        <div className={cn('BtnRadio', { invisible: isTranslationMode })}>
          <label className='drop_label mb-25'>Consistency: </label>

          <RadioButton
            label='Still'
            className='btnSetRadio'
            id='consist1'
            name='consist'
            value='still'
            checked={bottleFormik.values.consist === 'still'}
            onChange={handleChange}
          />
          <RadioButton
            label='Sparkling'
            className='btnSetRadio'
            id='consist2'
            name='consist'
            value='sparking'
            checked={bottleFormik.values.consist === 'sparking'}
            onChange={handleChange}
          />
          {bottleFormik.touched.consist && (
            <p
              style={{
                color: 'red',
                fontSize: '13px',
                marginRight: 'auto',
                marginLeft: '0',
              }}
            >
              {bottleFormik.errors.consist}
            </p>
          )}
        </div>

        <div className='cus_drop inputField__wrap'>
          <label className='drop_label'>
            Sweetness of wine<sup>*</sup>
          </label>
          <select
            id='sweetness_wine_id'
            value={bottleFormik.values.sweetness_wine_id}
            onChange={(e) => bottleFormik.setFieldValue('sweetness_wine_id', e.target.value)}
            disabled={isTranslationMode}
          >
            <option value='' disabled>
              Select sweetness
            </option>
            {bottleFormik.values.consist !== 'still' &&
              bottleFormik.values.consist !== 'sparking' &&
              sweetNessList.map((option) => (
                <option key={option.id} value={option.id}>
                  {option.name}
                </option>
              ))}
            {bottleFormik.values.consist === 'still' &&
              stillList.map((option) => (
                <option key={option.id} value={option.id}>
                  {option.name}
                </option>
              ))}
            {bottleFormik.values.consist === 'sparking' &&
              sparklingList.map((option) => (
                <option key={option.id} value={option.id}>
                  {option.name}
                </option>
              ))}
          </select>
          {bottleFormik.touched.sweetness_wine_id && (
            <p
              style={{
                color: 'red',
                fontSize: '13px',
                marginRight: 'auto',
                marginLeft: '0',
              }}
            >
              {bottleFormik.errors.sweetness_wine_id}
            </p>
          )}
        </div>

        <InputField
          name='classification'
          label='Classification'
          placeholder='(Ex. Grand Cru, Premier Cru, DOCG)'
          id='classification'
          onChange={(event) => {
            bottleFormik.setFieldValue('classification', event.target.value)
          }}
          value={bottleFormik.values.classification}
          formik={bottleFormik}
        />
        <div className={cn('cus_drop inputField__wrap', { invisible: isTranslationMode })}>
          <label className='drop_label'>
            Size<sup>*</sup>
          </label>
          <select
            id='size_id'
            value={bottleFormik.values.size_id}
            onChange={(e) => handleSizeChange(e, 'size_id')}
            disabled={isTranslationMode}
          >
            <option value='' disabled>
              Select size
            </option>
            {sizeList.map((option) => (
              <option key={`sz-${option.id}`} value={option.id}>
                {option.size}
              </option>
            ))}
          </select>
          {bottleFormik.touched.size_id && (
            <p
              style={{
                color: 'red',
                fontSize: '13px',
                marginRight: 'auto',
                marginLeft: '0',
              }}
            >
              {bottleFormik.errors.size_id}
            </p>
          )}
        </div>
        <div className={cn('cus_drop inputField__wrap', { invisible: isTranslationMode })}>
          <label className='drop_label'>Badge</label>
          <select
            id='badge'
            value={bottleFormik.values.badge}
            onChange={(e) => handleBadgeChange(e, 'badge')}
            disabled={isTranslationMode}
          >
            <option value=''></option>
            {badgeSelectList.map((option) => (
              <option key={`bgs-${option}`} value={option}>
                {option}
              </option>
            ))}
          </select>
          {bottleFormik.touched.badge && (
            <p
              style={{
                color: 'red',
                fontSize: '13px',
                marginRight: 'auto',
                marginLeft: '0',
              }}
            >
              {bottleFormik.errors.badge}
            </p>
          )}
        </div>
      </div>
      <Notes bottleFormik={bottleFormik} action='edit' isTranslationMode={isTranslationMode} />
    </Form>
  )
}

export default BottleForm
