import { useMemo, useState, useEffect } from 'react'
import { Col, Row } from 'antd'
import cn from 'classnames'
import countryList from 'react-select-country-list'
import MainLayout from '../../../../components/HOC/MainLayout/MainLayout'
import WineftLogs from './WineftLogs'
import CardIframe from '../../../../components/common/CardIframe'

import { Bottles } from '../../../../redux/_actions'
import { useDispatch } from 'react-redux'
import './EditWineft.scss'
import cloneDeep from 'lodash/cloneDeep'

import {
  getNftDetails,
  updateNftDetails,
  sendToApproveNft,
  saveDropLogs,
  getDropLogs,
  translateNftAutomatically,
} from '../../../../redux/_actions/nft.action'
import CustomDropdown from '../../../../components/common/CustomDropdown/CustomDropdown'
import moment from 'moment'
import { useHistory } from 'react-router'
import { toast } from '../../../../components/Toast/Toast'
import { CREATE_NFT_STATUSES } from '../data/consts'
import getWineNameFromBottleDetails from '../helpers/helpers'
import { tagCustomEventSendPulse } from '../../../../utils/sendPulseApi/sendPulseApi'
import { SENDPULSE_EVENTS } from '../../../../utils/sendPulseApi/constants/constants'
import WineftForm from './WineftForm'
import { ButtonCustom } from 'components/button/ButtonCustom'
import WineftTranslation from './WineftTranslation/WineftTranslation'

const { getBottleDetail } = Bottles

const EditWineft = (props) => {
  let dropDetails = props.location.state
  const history = useHistory()

  const [start_drop_event_date, setstart_drop_event] = useState()
  const [finish_drop_event_date, setFinish_drop_event_date] = useState()
  const [bottle_release_dates, setBottle_release_dates] = useState()
  const [nftDetails, setNftDetails] = useState({})
  const [showBottom, setShowBottom] = useState(true)
  const [isActive, setActive] = useState(false)
  const [allcountryList, setAllCountryList] = useState([])
  const [allcountryListSearch, setAllCountryListSearch] = useState([])
  const [searchedText, setSearchedText] = useState([])
  const options = useMemo(() => countryList().getData(), [])
  const [openCountryModal, setOpenCountryModal] = useState(false)
  const [bottleDetails, setBottleDetail] = useState({})
  const [logsData, setLogs] = useState({})

  const [countyBeforeOpen, setCountryBeforeOpen] = useState([])

  const dispatch = useDispatch()
  const [merchants, setMerchants] = useState([])

  const [isEmptyDrop, setIsEmptyDrop] = useState(false)
  const [isTranslationMode, setIsTranslationMode] = useState(false)

  useEffect(() => {
    getBottleDetails()
    getLogsData()
  }, [])

  const saveEditNftDetails = (data) => {
    dispatch(updateNftDetails(data)).then((res) => {
      toast.success(res.data.message)
      saveMyDropDataLogs(data)
    })
  }

  const getLogsData = () => {
    let data = {
      id: dropDetails?.id,
      formType: 'dropData',
    }

    dispatch(getDropLogs(data)).then((res) => {
      let dropLogsData = res.data?.data?.drop
      setLogs(dropLogsData)
    })
  }

  const saveMyDropDataLogs = (newData) => {
    delete newData['id']
    let oldData = {
      bottle_release_date: nftDetails?.bottle_release_date,
      finish_drop_event: getDateFormatOld(nftDetails?.finish_drop_event),
      is_exclusive_list: nftDetails?.is_exclusive_list,
      is_visible_count: nftDetails?.is_visible_count,
      max_buy_limit: nftDetails?.max_buy_limit,
      no_of_bottles: nftDetails?.no_of_bottles,
      no_of_nft: nftDetails?.no_of_nft,
      price: nftDetails?.price,
      royality_payment_profilt: nftDetails?.royality_payment_profilt,
      shipping_location: nftDetails?.shipping_location,
      start_drop_event: getDateFormatOld(nftDetails?.start_drop_event),
      timezone: nftDetails?.timezone,
      vintage: nftDetails?.vintage,
      abv: nftDetails?.abv,
      price_ex_vat: nftDetails?.price_ex_vat,
      nft_name: nftDetails?.nft_name,
      nft_subtitle: nftDetails?.nft_subtitle,
      winery_perks: nftDetails?.nft_data?.winery_perks,
      drop_mode: nftDetails?.drop_mode,
      is_empty: nftDetails?.is_empty,
      merchant_type: nftDetails?.merchant_type,
      commission_primary: nftDetails?.commission_primary,
      commission_secondary: nftDetails?.commission_secondary,
      cellar_price: nftDetails?.cellar_price,
    }

    let finalData = {
      formType: 'dropData',
      prevData: oldData,
      newData: newData,
      id: nftDetails.id,
      userId: nftDetails.userId,
    }

    dispatch(saveDropLogs(finalData)).then((res) => {
      if (!isTranslationMode) {
        history.push('/panel/Dropevents')
      }
    })
  }

  const getBottleDetails = () => {
    let bottleDetails = {
      id: dropDetails?.bottle_id,
    }
    dispatch(getBottleDetail(bottleDetails)).then((res) => {
      setBottleDetail(res.data.data)
      setMerchants(res.data.merchants)
    })
  }

  const getDateFormat = (date, time) => {
    let check = moment(date, 'YYYY/MM/DD hh:mm:ss')

    let finalDate = {}

    if (time) {
      let hours = date.getHours()
      let minutes = date.getMinutes()

      minutes = minutes < 10 ? `${minutes}` : minutes
      finalDate = {
        year: parseInt(check.format('YYYY')),
        month: parseInt(check.format('M')),
        day: parseInt(check.format('D')),
        hour: hours,
        minute: parseInt(minutes),
      }
    } else {
      finalDate = {
        year: parseInt(check.format('YYYY')),
        month: parseInt(check.format('M')),
        day: parseInt(check.format('D')),
      }
    }

    return finalDate
  }

  const getDateFormatOld = (date) => {
    let check = moment(date, 'YYYY/MM/DD hh:mm:ss')
    return (
      parseInt(check.format('YYYY')) +
      '-' +
      parseInt(check.format('M')) +
      '-' +
      parseInt(check.format('D')) +
      ' ' +
      parseInt(check.format('h')) +
      ':' +
      parseInt(check.format('m'))
    )
  }

  const loadNftDetails = async () => {
    const data = {
      id: dropDetails?.id,
    }
    return dispatch(getNftDetails(data)).then((res) => {
      setNftDetails(res.data.data)
      return res.data.data
    })
  }

  const getparticularNftDetails = () => {
    const data = {
      id: dropDetails?.id,
    }
    dispatch(getNftDetails(data)).then((res) => {
      setCountryToTop(res.data?.data?.shipping_location)
      setNftDetails(res.data.data)
      setIsEmptyDrop(res.data.data.is_empty)

      const start_drop_event = getDateFormat(new Date(res.data.data.start_drop_event), true)
      const finish_drop_event = getDateFormat(new Date(res.data.data.finish_drop_event), true)

      const setBottle_release_date = getDateFormat(res.data.data.bottle_release_date, false)
      setstart_drop_event(start_drop_event)

      setFinish_drop_event_date(finish_drop_event)
      setBottle_release_dates(setBottle_release_date)

      //save old data here
    })
  }

  useEffect(() => {
    const body = document.querySelector('body')
    document.body.classList.add('bg-white')
    return () => {
      body.classList.remove('bg-white')
    }
  })

  useEffect(() => {
    getparticularNftDetails()
  }, [])

  const ToggleClass = () => {
    setActive(!isActive)
  }

  const onSelectCountry = (index) => {
    let country = [...allcountryList]
    country[index].isChecked = !country[index].isChecked
    setAllCountryList(country)
    setAllCountryListSearch(country)
  }

  const onSelectCountrySearch = (item) => {
    let countrySearch = [...allcountryListSearch]
    let country = [...allcountryList]

    countrySearch.forEach((element) => {
      if (element.label === item.label) {
        element.isChecked = !element.isChecked
      }
    })

    setAllCountryList(country)
    setAllCountryListSearch(countrySearch)
  }

  const onOpen = () => {
    let country = cloneDeep(allcountryList)
    setCountryBeforeOpen(country)
    setOpenCountryModal(true)
  }

  const onClose = () => {
    let c = [...countyBeforeOpen]
    setAllCountryList(c)

    setOpenCountryModal(false)
  }

  const onChangeSearchText = (value) => {
    setSearchedText(value)
    if (value === '') {
      setAllCountryListSearch([])
    } else {
      let searchCountry = [...allcountryList]
      let finalCountry = []

      searchCountry.forEach((element) => {
        if (element.label.toLowerCase().includes(value.toLowerCase())) {
          finalCountry.push(element)
        }
      })
      setAllCountryListSearch(finalCountry)
    }
  }

  const clearAllCountries = () => {
    let country = [...allcountryList]
    country.forEach((element) => {
      element.isChecked = false
    })
    setAllCountryList(country)
    setAllCountryListSearch(country)
    setSearchedText('')
  }

  const acceptSelectedCountries = () => {
    let country = [...allcountryList]
    let shipping_location = []

    country.forEach((element) => {
      if (element.isChecked) {
        shipping_location.push(element.label)
      }
    })

    if (shipping_location.length) {
      setOpenCountryModal(false)
      setSearchedText('')
    } else {
      toast.error('Please choose at least one country')
    }
  }

  const getTotalSelectedCount = () => {
    let country = [...allcountryList]

    let count = 0

    country.forEach((element) => {
      if (element.isChecked) {
        count = count + 1
      }
    })

    return count
  }
  const setCountryToTop = (country) => {
    // putting some selected country to top of list
    let allC = []

    options.forEach((element) => {
      // setting default selected country as per records
      if (country.includes(element.label)) {
        element.isChecked = true
      }

      if (
        element.label !== 'France' &&
        element.label !== 'Italy' &&
        element.label !== 'Spain' &&
        element.label !== 'United States' &&
        element.label !== 'Germany' &&
        element.label !== 'South Africa' &&
        element.label !== 'Australia'
      ) {
        allC.push(element)
      } else {
        allC.unshift(element)
      }
    })
    setAllCountryList(allC)
    setAllCountryListSearch(allC)
  }

  const sendNftToApprove = async (nftFormik, getCollectedFormData) => {
    const errors = await nftFormik.validateForm()
    if (Object.keys(errors).length === 0) {
      // Form is valid, do any success call

      const formValues = getCollectedFormData()

      if (formValues.shipping_location.length || isEmptyDrop) {
        dispatch(updateNftDetails(formValues)).then((res) => {
          toast.success(res.data.message)
          saveMyDropDataLogs(formValues)

          let data = {
            id: nftDetails.id,
            status: CREATE_NFT_STATUSES.APPROVED,
          }
          dispatch(sendToApproveNft(data)).then(
            async (res) => {
              const dropName = getWineNameFromBottleDetails({
                bottleDetails,
                nft_name: nftFormik.values.nft_name,
              })
              await tagCustomEventSendPulse({
                eventLink: SENDPULSE_EVENTS.DROP_PUBLISHED,
                email: bottleDetails.email,
                customData: { drop_name: dropName },
              })
              toast.success(res.data.message)
              history.push('/panel/Dropevents')
            },
            (error) => {
              toast.error(error.data.message)
            },
          )
        })
      } else {
        toast.error('Please choose at least one country')
      }
    } else {
      nftFormik.setTouched(errors, true)
    }
  }

  const setAllSelected = () => {
    let country = [...allcountryList]
    country.forEach((element) => {
      element.isChecked = true
    })
    setAllCountryList(country)
    setAllCountryListSearch(country)
    setSearchedText('')
  }

  const translateNftAuto = () => {
    dispatch(translateNftAutomatically({ dropId: nftDetails?.drop_id }))
  }

  return (
    <>
      <MainLayout>
        <div className='winemakers-main crtbtl EditWineft'>
          <div
            className={cn('left', {
              brdr: isActive,
              hidden: isTranslationMode,
            })}
          >
            <div className='logs-controller'>
              <div className='top'>
                <h3 className='common-sub-heading'>Logs</h3>

                <button
                  className={showBottom ? 'active logs-toggler' : 'logs-toggler'}
                  type='button'
                  onClick={() => {
                    setShowBottom(!showBottom)
                    ToggleClass()
                  }}
                ></button>
              </div>
              {showBottom && (
                <WineftLogs
                  data={logsData}
                  mainTitle='Is NFT count visible to user?'
                  oldValues='Bottleold'
                  newValues='BottleNew'
                />
              )}
            </div>
          </div>

          <div className='right'>
            <Row className='rightrow'>
              <Col xl={12} className='crtbtl-left'>
                <div className='wineNFTHeader'>
                  <h1 className='common-heading HeadingSpace'>Edit WiNeFT</h1>
                  <div className='translationBtnWrapper'>
                    {!isTranslationMode && (
                      <>
                        <ButtonCustom
                          onClick={translateNftAuto}
                          title={'Translate Automatically'}
                        />
                        <ButtonCustom
                          onClick={() => setIsTranslationMode(true)}
                          title={'Translate Manually'}
                        />
                      </>
                    )}
                  </div>
                </div>

                <WineftForm
                  isEmptyDrop={isEmptyDrop}
                  setIsEmptyDrop={setIsEmptyDrop}
                  nftDetails={nftDetails}
                  bottleDetails={bottleDetails}
                  dropDetails={dropDetails}
                  start_drop_event_date={start_drop_event_date}
                  finish_drop_event_date={finish_drop_event_date}
                  setFinish_drop_event_date={setFinish_drop_event_date}
                  bottle_release_dates={bottle_release_dates}
                  allcountryList={allcountryList}
                  onOpen={onOpen}
                  getTotalSelectedCount={getTotalSelectedCount}
                  merchants={merchants}
                  sendNftToApprove={sendNftToApprove}
                  onSubmit={saveEditNftDetails}
                  hideApproveBtn={isTranslationMode}
                />
              </Col>
              {isTranslationMode && (
                <Col xl={12} className='crtbtl-right'>
                  <WineftTranslation
                    isEmptyDrop={isEmptyDrop}
                    setIsEmptyDrop={setIsEmptyDrop}
                    nftDetails={nftDetails}
                    bottleDetails={bottleDetails}
                    dropDetails={dropDetails}
                    start_drop_event_date={start_drop_event_date}
                    finish_drop_event_date={finish_drop_event_date}
                    setFinish_drop_event_date={setFinish_drop_event_date}
                    bottle_release_dates={bottle_release_dates}
                    allcountryList={allcountryList}
                    onOpen={onOpen}
                    getTotalSelectedCount={getTotalSelectedCount}
                    merchants={merchants}
                    loadNftDetails={loadNftDetails}
                    setIsTranslationMode={setIsTranslationMode}
                    isTranslationMode={isTranslationMode}
                  />
                </Col>
              )}

              <Col xl={12} className={cn('crtbtl-right', { hidden: isTranslationMode })}>
                <div className='inner'>
                  <div className='Header'>
                    <h3 className='common-sub-heading'>Token card Preview</h3>
                  </div>
                  <CardIframe
                    data={{
                      ...dropDetails,
                      ...nftDetails,
                      ...{
                        // ...nftFormik.values, // it wasn't working properly even now
                        start_drop_event: nftDetails.start_drop_event,
                        finish_drop_event: nftDetails.finish_drop_event,
                      },
                      users: { creator_meta: { country_code: bottleDetails.countrycode } },
                      bottle_data: {
                        winery_name: nftDetails?.bottle_data?.creator_meta?.winery_name,
                        ...bottleDetails,
                      },
                    }}
                  />
                </div>
              </Col>
            </Row>
            <CustomDropdown
              option={searchedText === '' ? allcountryList : allcountryListSearch}
              onSelect={onSelectCountry}
              onSelectSearchedCountry={onSelectCountrySearch}
              searchedText={searchedText}
              onSearch={onChangeSearchText}
              onClose={onClose}
              onOpen={onOpen}
              open={openCountryModal}
              clearAllCountries={clearAllCountries}
              onAccept={acceptSelectedCountries}
              getTotalSectected={getTotalSelectedCount}
              setAllSelected={setAllSelected}
            />
          </div>
        </div>
      </MainLayout>
    </>
  )
}

export default EditWineft
