import { startLoading, stopLoading } from './loading.action'
// import { toast } from '../../components/Toast/Toast'
import { TranslationService } from 'Services/translation.service'
/** seting action types */
export const actionTypes = {
  SAVE_ALL_TRANSLATIONS: 'SAVE_ALL_TRANSLATIONS',
}

export function saveAllTranslations(data) {
  return {
    type: actionTypes.SAVE_ALL_TRANSLATIONS,
    payload: data,
  }
}

export function getAllTranslations(data) {
  return (dispatch, getState) =>
    new Promise((resolve, reject) => {
      dispatch(startLoading())
      TranslationService.getTranslation(data, {
        Authorization: localStorage.getItem('authorizations'),
      })
        .then((res) => {
          dispatch(
            saveAllTranslations({ translations: res.data?.data, language: data.language_id }),
          )
          dispatch(stopLoading())

          resolve(res)
        })
        .catch((ex) => {
          dispatch(stopLoading())
          reject(ex)
        })
    })
}

export function updateTranslations(data, succesFn = () => {}, errorFn = () => {}) {
  return (dispatch, getState) =>
    new Promise((resolve, reject) => {
      dispatch(startLoading())
      TranslationService.updateTranslation(data, {
        Authorization: localStorage.getItem('authorizations'),
      })
        .then((res) => {
          dispatch(stopLoading())
          succesFn(res)
          resolve(res)
        })
        .catch((ex) => {
          errorFn(ex)
          dispatch(stopLoading())
          reject(ex)
        })
    })
}
