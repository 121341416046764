import { useMemo, useState } from 'react'
import cn from 'classnames'
import DropdownField from 'components/common/DropdownField/DropdownField'
import { useDispatch, useSelector } from 'react-redux'
import { getAllTranslations, updateTranslations } from 'redux/_actions/translations.action'
import BottleForm from '../BottleForm'

import comCSS from './BottleTranslation.module.scss'
import { keyBy } from 'lodash'
import languages from '_constants/languages'

const translationsKeysData = [
  {
    key: 'description',
    keyTextContent: 'description_text_content_id',
    // value: data.description, // is used later
  },
  {
    key: 'review',
    keyTextContent: 'review_text_content_id',
  },
  {
    key: 'about_winery',
    keyTextContent: 'about_winery_text_content_id',
  },
  {
    key: 'terroir_and_aging_method',
    keyTextContent: 'terroir_and_aging_method_text_content_id',
  },
  {
    key: 'tasting_notes',
    keyTextContent: 'tasting_notes_text_content_id',
  },
  {
    key: 'pairing',
    keyTextContent: 'pairing_text_content_id',
  },
  {
    key: 'grape',
    keyTextContent: 'grape_text_content_id',
  },
  {
    key: 'classification',
    keyTextContent: 'classification_text_content_id',
  },
  {
    key: 'wine_name',
    keyTextContent: 'wine_name_text_content_id',
  },
  {
    key: 'bottle_image_title',
    keyTextContent: 'bottle_image_title_text_content_id',
  },
  {
    key: 'bottle_image_alt',
    keyTextContent: 'bottle_image_alt_text_content_id',
  },
  // country is handled separatelly
  // {
  //   key: 'country',
  //   keyTextContent: 'country_text_content_id',
  // }
]

const BottleTranslation = ({
  getBottleDetails,
  bottleId,
  bottleDetail,
  handlefilterOnWinerySelect,
  wineryList,
  handleCountryChange,
  allCountry,
  regionList,
  appellationWithRegion,
  colorList,
  sweetNessList,
  stillList,
  sparklingList,
  handleSizeChange,
  sizeList,
}) => {
  const dispatch = useDispatch()
  const { translations } = useSelector((state) => state?.translation)
  const [language, setLanguage] = useState('')

  const bottleData = useMemo(() => {
    const bottle = { ...bottleDetail }
    const translationsMap = keyBy(translations, 'text_content_id')
    translationsKeysData.forEach((el) => {
      bottle[el.key] = translationsMap[bottleDetail[el.keyTextContent]]?.translation || ''
    })

    if (bottleDetail.country_text_content_id) {
      bottle.country = translationsMap[bottleDetail.country_text_content_id]?.translation || ''
    }

    return bottle
  }, [bottleDetail, translations])

  // show translations for dropdowns. They are disabled in bottle form
  const lists = useMemo(() => {
    const translationsMap = keyBy(translations, 'text_content_id')

    const regionListNew = regionList.map((el) => ({
      ...el,
      region_name:
        bottleDetail.region_obj?.id === el.id && (language !== '---' || !language)
          ? translationsMap[bottleDetail.region_obj?.region_name_text_content_id]?.translation || ''
          : el.region_name,
    }))

    const sweetNessListNew = sweetNessList.map((el) => ({
      ...el,
      name:
        bottleDetail.wine_sweetness?.id === el.id && (language !== '---' || !language)
          ? translationsMap[bottleDetail.wine_sweetness?.name_text_content_id]?.translation || ''
          : el.name,
    }))
    const stillListNew = stillList.map((el) => ({
      ...el,
      name:
        bottleDetail.wine_sweetness?.id === el.id && (language !== '---' || !language)
          ? translationsMap[bottleDetail.wine_sweetness?.name_text_content_id]?.translation || ''
          : el.name,
    }))
    const sparklingListNew = sparklingList.map((el) => ({
      ...el,
      name:
        bottleDetail.wine_sweetness?.id === el.id && (language !== '---' || !language)
          ? translationsMap[bottleDetail.wine_sweetness?.name_text_content_id]?.translation || ''
          : el.name,
    }))
    const colorListNew = colorList.map((el) => ({
      ...el,
      color_name:
        bottleDetail.wine_color_id === el.id && (language !== '---' || !language)
          ? translationsMap[bottleDetail.wine_color?.color_name_text_content_id]?.translation || ''
          : el.color_name,
    }))
    const appellationWithRegionNew = appellationWithRegion.map((el) => ({
      ...el,
      appellation:
        bottleDetail.appellation === el.id && (language !== '---' || !language)
          ? translationsMap[bottleDetail.appellation_obj?.appellation_text_content_id]
              ?.translation || el.appellation // el.appellation has to be changed with ''
          : el.appellation,
    }))

    const countries = allCountry.map((el) => {
      if (bottleDetail.country === el) {
        return translationsMap[bottleDetail.country_text_content_id]?.translation || ''
      }

      return el
    })

    return {
      regionList: regionListNew,
      sweetNessList: sweetNessListNew,
      colorList: colorListNew,
      countries,
      stillList: stillListNew,
      sparklingList: sparklingListNew,
      appellationWithRegion: appellationWithRegionNew,
    }
  }, [
    bottleDetail,
    translations,
    regionList,
    sweetNessList,
    colorList,
    allCountry,
    stillList,
    sparklingList,
    appellationWithRegion,
    language,
  ])

  const getTranslationsIds = () => {
    const result = translationsKeysData
      .map((el) => bottleDetail[el.keyTextContent])
      .filter((el) => el)
    if (bottleDetail.country_text_content_id) {
      result.push(bottleDetail.country_text_content_id)
    }
    if (bottleDetail?.region_obj?.region_name_text_content_id) {
      result.push(bottleDetail.region_obj.region_name_text_content_id)
    }
    if (bottleDetail?.wine_sweetness?.name_text_content_id) {
      result.push(bottleDetail.wine_sweetness?.name_text_content_id)
    }
    if (bottleDetail?.wine_color?.color_name_text_content_id) {
      result.push(bottleDetail.wine_color?.color_name_text_content_id)
    }
    if (bottleDetail?.appellation_obj?.appellation_text_content_id) {
      result.push(bottleDetail.appellation_obj.appellation_text_content_id)
    }

    return result
  }
  const loadTranslations = (language_id) => {
    const ids = getTranslationsIds().join(',')
    dispatch(getAllTranslations({ ids, language_id }))
  }

  const onLanguageSelect = (e) => {
    setLanguage(e.target.value)
    loadTranslations(e.target.value)
  }

  const saveTranslations = (values) => {
    const allTranslations = translationsKeysData
      .map((el) => ({
        ...el,
        value: bottleDetail[el.key] || null, // original value in en
        translation: values[el.key] || null, // e.g. values.reviews
        text_content_id: bottleDetail[el.keyTextContent] || null,
      }))
      .filter((el) => el.text_content_id !== null || el.translation !== null)
    const data = {
      model: 'winer_bottle',
      primaryKey: 'bottle_id',
      primaryKeyValue: bottleId,
      translations: allTranslations,
      language_id: language,
    }
    dispatch(updateTranslations(data, getBottleDetails))
  }

  return (
    <div className={cn(comCSS.wrapper)}>
      <DropdownField
        onChange={onLanguageSelect}
        options={languages}
        value={language}
        label='Choose language'
        className={comCSS.languageSelector}
      />
      {language && language !== '---' && (
        <BottleForm
          bottleId={bottleId}
          bottleDetail={bottleData}
          onSubmit={saveTranslations}
          handlefilterOnWinerySelect={handlefilterOnWinerySelect}
          wineryList={wineryList}
          handleCountryChange={handleCountryChange}
          allCountry={lists.countries}
          // allCountry={allCountry}
          regionList={lists.regionList}
          appellationWithRegion={lists.appellationWithRegion}
          colorList={lists.colorList}
          sweetNessList={lists.sweetNessList}
          stillList={lists.stillList}
          sparklingList={lists.sparklingList}
          handleSizeChange={handleSizeChange}
          sizeList={sizeList}
          isTranslationMode={true}
        />
      )}
    </div>
  )
}

BottleTranslation.propTypes = {}

export default BottleTranslation
