import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Col, Row } from 'antd'
import cn from 'classnames'
import MainLayout from '../../../../components/HOC/MainLayout/MainLayout'
import { toast } from '../../../../components/Toast/Toast.js'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router'
import { ReactComponent as IconBack } from '../../../../Assets/image/svgimages/icon_back.svg'
import { Bottles, WinerMakerAction } from '../../../../redux/_actions'
import BottleLogs from './BottleLogs'
import 'antd/dist/antd.min.css'
import '../../CreateWinemakers/CreateWinemakers.scss'
import '../CreateBottle/CreateBottle.scss'
import './EditBottle.scss'

import CardIframe from '../../../../components/common/CardIframe'
import BottleForm from './BottleForm'
import { ButtonCustom } from 'components/button/ButtonCustom'
import BottleTranslation from './BottleTranslation/BottleTranslation'
import { translateBottleAutomatically } from 'redux/_actions/bottles.action'

const setLogItemValue = (id, arr = [], key = null, fn = () => {}) => {
  if (!id) return
  const value = arr.find((el) => `${el.id}` === `${id}`)?.[key]
  if (!value) return
  fn(value)
}

const EditBottle = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const [logsData, setLogsData] = useState({})
  const [wineryList, setWineryList] = useState([])
  const [colorList, setColorList] = useState([])
  const [allCountry, setAllCountry] = useState([])
  const [sizeList, setSizeList] = useState([])
  const [regionList, setRegionList] = useState([])
  const [sweetNessList, setSweetNessList] = useState([])
  const [stillList, setStillList] = useState([])
  const [sparklingList, setSparklingList] = useState([])

  const [appellation, setAppellation] = useState([])
  const [appellationWithRegion, setAppellationWithRegion] = useState([])

  const [countryWithRegion, setCountryWithRegion] = useState([])
  const [bottleDetail, setBottleDetail] = useState({})
  const [sizeText, setSizeText] = useState('') //for preview bottle
  const [sizeTextOld, setSizeTextOld] = useState('') //for preview bottle
  const [badgeText, setBadgeText] = useState('') //for preview bottle
  const [badgeTextOld, setBadgeTextOld] = useState('') //for preview bottle
  const [regionTextOld, setRegionTextOld] = useState('') //for preview bottle
  const [regionText, setRegionText] = useState('') //for preview bottle
  const [appellationOldText, setAppellationTextOld] = useState('') //for preview bottle
  const [appellationNewText, setAppellationNewText] = useState('')
  const [colorTextOld, setColorTextOld] = useState('')
  const [colorTextNew, setColorTextNew] = useState('')

  const [showBottom, setShowBottom] = useState(true)
  const [isActive, setActive] = useState(false)
  const [isTranslationMode, setIsTranslationMode] = useState(false)

  const { saveProfileOldData, getPublicDetailsLogs } = WinerMakerAction
  const {
    getBottleDropDownSpecifications,
    getBottleDetail,
    updateBottle,
    getAllWineryListForDropDown,
  } = Bottles

  useEffect(() => {
    const body = document.querySelector('body')
    document.body.classList.add('bg-white')
    return () => {
      body.classList.remove('bg-white')
    }
  })

  const bottleId = history?.location?.state?.bottle_id
  const userId = history?.location?.state?.id

  useEffect(() => {
    if (!userId) {
      history.push('/panel/bottles')
    }
  }, [userId])

  useEffect(() => {
    // Set old values for logs
    //for size
    setLogItemValue(logsData.bottle?.oldValues?.size_id, sizeList, 'size', setSizeTextOld)

    //for badge
    setLogItemValue(
      logsData.bottle?.oldValues?.badge,
      badgeSelectList.map((el) => ({ id: el, badge: el })),
      'badge',
      setBadgeTextOld,
    )
    setLogItemValue(
      logsData.bottle?.newValues?.badge,
      badgeSelectList.map((el) => ({ id: el, badge: el })),
      'badge',
      setBadgeText,
    )

    //for region for old
    setLogItemValue(
      logsData.bottle?.newValues?.region_id,
      countryWithRegion[logsData.bottle?.newValues?.country || bottleDetail?.country],
      'region_name',
      setRegionTextOld,
    )

    //for latest region
    setLogItemValue(
      logsData.bottle?.oldValues?.region_id,
      countryWithRegion[logsData.bottle?.oldValues?.country || bottleDetail?.country],
      'region_name',
      setRegionText,
    )

    // save Old appellation
    setLogItemValue(
      logsData.bottle?.oldValues?.appellation,
      appellation,
      'appellation',
      setAppellationTextOld,
    )

    // save New appellation
    setLogItemValue(
      logsData.bottle?.newValues?.appellation,
      appellation,
      'appellation',
      setAppellationNewText,
    )

    // save Old wine_color_id
    setLogItemValue(
      logsData.bottle?.oldValues?.wine_color_id,
      colorList,
      'color_name',
      setColorTextOld,
    )

    // save new wine_color_id
    setLogItemValue(
      logsData.bottle?.newValues?.wine_color_id,
      colorList,
      'color_name',
      setColorTextNew,
    )
  }, [logsData])

  const ToggleClass = () => {
    setActive(!isActive)
  }

  useEffect(() => {
    getBottlesSpecification()
  }, [])

  useEffect(() => {
    // setting regions default value
    if (Object.keys(countryWithRegion).length) {
      getLogsDetails()

      for (let key in countryWithRegion) {
        if (key === bottleDetail?.country) {
          setRegionList(countryWithRegion[key])
        }
      }
      // set applilation as per region
      countryWithRegion[bottleDetail?.country]?.forEach((element) => {
        if (element.id === bottleDetail?.region_id) {
          setAppellationWithRegion(element.wine_appellation)
        }
      })

      //set size text

      sizeList.forEach((element) => {
        if (element.id === bottleDetail.size_id) {
          setSizeText(element.size)
        }
      })
      //set badge text

      badgeSelectList.forEach((element) => {
        if (element === bottleDetail.badge) {
          setBadgeText(element)
        }
      })
    }
  }, [bottleDetail])

  useEffect(() => {
    getAllWineryList()
  }, [])

  const getAllWineryList = () => {
    dispatch(getAllWineryListForDropDown()).then((res) => {
      setWineryList(res?.data?.data)
    })
  }

  const getLogsDetails = () => {
    let data = {
      userId: bottleDetail.user_id,
    }
    dispatch(getPublicDetailsLogs(data)).then((res) => {
      setLogsData(res?.data.data)
      // set if size changes
    })
  }

  const getBottlesSpecification = () => {
    dispatch(getBottleDropDownSpecifications()).then((res) => {
      const details = res.data
      const keys = Object.keys(details.regionList)
      setCountryWithRegion(details.regionList)
      setSizeList(details.SizeList)
      setColorList(details?.colorList?.reverse())
      setAppellation(details.appellationList)

      const still = details?.BottleSweetnessList?.filter(
        (b) => b?.wine_type === 'still' || b?.wine_type === null,
      )
      const sparkling = details?.BottleSweetnessList?.filter(
        (item) => item?.wine_type === 'sparkling' || item?.wine_type === null,
      )

      const sparklingSortOrder = [
        'Brut nature',
        'Extra-brut',
        'Brut',
        'Extra-dry',
        'Dry',
        'Semi-dry',
        'Sweet',
      ]

      sparkling.sort((a, b) => {
        const aIndex = sparklingSortOrder.indexOf(a.name)
        const bIndex = sparklingSortOrder.indexOf(b.name)
        return aIndex - bIndex
      })

      setStillList(still.length > 0 ? still : [])
      setSparklingList(sparkling.length > 0 ? sparkling : [])

      setSweetNessList(details.BottleSweetnessList)
      setAllCountry(keys)

      getBottleDetails()
    })
  }
  const getBottleDetails = () => {
    let bottleDetails = {
      id: bottleId,
    }
    dispatch(getBottleDetail(bottleDetails)).then((res) => {
      setBottleDetail(res.data.data)
    })
  }

  const handlefilterOnWinerySelect = (targetValue) => {
    const selectedWinery = wineryList.find((el) => el.winery_name === targetValue)

    for (const key in countryWithRegion) {
      if (key === selectedWinery?.country) {
        setRegionList(countryWithRegion[key])
        break
      }
    }

    countryWithRegion[selectedWinery?.country].forEach((element) => {
      if (element.id === selectedWinery.region_obj?.id) {
        setAppellationWithRegion(element.wine_appellation)
      }
    })

    return selectedWinery
  }

  const updateBottleDetails = (data) => {
    dispatch(updateBottle(data)).then((res) => {
      toast.success(res.data.message)
      //save logs here
      saveBottleDataForLogs(data)
    })
  }
  const saveBottleDataForLogs = (newData) => {
    let pData = { ...bottleDetail }
    delete pData.id
    delete pData.user_id
    delete pData.bottle_image
    delete pData.vintage

    let details = {
      prevData: pData,
      newData: newData,
      formType: 'bottles',
      userId: bottleDetail.user_id,
    }

    dispatch(saveProfileOldData(details)).then((res) => {
      if (isTranslationMode) {
        getBottleDetails()
        return
      }
      history.push('/panel/bottles')
    })
  }

  const handleCountryChange = (e, field, country) => {
    if (field === 'country') {
      setAppellationWithRegion([])
      for (const key in countryWithRegion) {
        if (key === e.target.value) {
          setRegionList(countryWithRegion[key])
        }
      }
    }
    if (field === 'region_id') {
      countryWithRegion[country].forEach((element) => {
        if (element.id === e.target.value) {
          setAppellationWithRegion(element.wine_appellation)
        }
      })
    }
  }

  const handleSizeChange = (e, field) => {
    sizeList.forEach((element) => {
      if (element.id === e.target.value) {
        setSizeText(element.size)
      }
    })
    //set size text with id
  }

  const badgeSelectList = ['Icon', 'Rising Star', 'Hidden Gem']
  const translateBottleAuto = () => {
    dispatch(translateBottleAutomatically({ id: bottleDetail.id, user_id: bottleDetail.user_id }))
  }

  return (
    <MainLayout>
      <div className='winemakers-main create-public-page crtbtl edt'>
        <div
          className={cn('left', {
            brdr: isActive,
            hidden: isTranslationMode,
          })}
        >
          <div className='logs-controller'>
            <div className='top'>
              <h3 className='common-sub-heading'>Logs</h3>

              <button
                className={cn('logs-toggler', {
                  active: isActive,
                })}
                type='button'
                onClick={() => {
                  setShowBottom(!showBottom)
                  ToggleClass()
                }}
              ></button>
            </div>
            {showBottom && (
              <BottleLogs
                data={logsData}
                sizeText={sizeText}
                sizeTextOld={sizeTextOld}
                badgeText={badgeText}
                badgeTextOld={badgeTextOld}
                regionText={regionText}
                regionTextOld={regionTextOld}
                appellationOldText={appellationOldText}
                appellationNewText={appellationNewText}
                colorTextOld={colorTextOld}
                colorTextNew={colorTextNew}
              />
            )}
          </div>
        </div>

        <div className='right'>
          <Row className='rightrow'>
            <Col xs={24}>
              <div className='bottleHeader'>
                <div>
                  <Link to='/panel/bottles' className='back-btn'>
                    <IconBack /> Back to bottles list
                  </Link>
                  <h1 className='common-heading mb-25'>Edit Wine Bottle</h1>
                </div>
                <div className={cn('bottleBtnWrapper')}>
                  {isTranslationMode ? (
                    <ButtonCustom
                      onClick={() => setIsTranslationMode(false)}
                      title={'Close Translation'}
                    />
                  ) : (
                    <>
                      <ButtonCustom
                        onClick={translateBottleAuto}
                        title={'Translate Automatically'}
                      />
                      <ButtonCustom
                        onClick={() => setIsTranslationMode(true)}
                        title={'Translate Manually'}
                      />
                    </>
                  )}
                </div>
              </div>
            </Col>
            <Col xl={isTranslationMode ? 12 : 14} className='crtbtl-left'>
              <BottleForm
                bottleId={bottleId}
                bottleDetail={bottleDetail}
                onSubmit={updateBottleDetails}
                handlefilterOnWinerySelect={handlefilterOnWinerySelect}
                wineryList={wineryList}
                handleCountryChange={handleCountryChange}
                allCountry={allCountry}
                regionList={regionList}
                appellationWithRegion={appellationWithRegion}
                colorList={colorList}
                sweetNessList={sweetNessList}
                stillList={stillList}
                sparklingList={sparklingList}
                handleSizeChange={handleSizeChange}
                sizeList={sizeList}
              />
            </Col>
            {isTranslationMode && (
              <Col xl={12} className='crtbtl-right'>
                <BottleTranslation
                  getBottleDetails={getBottleDetails}
                  bottleId={bottleId}
                  bottleDetail={bottleDetail}
                  onSubmit={updateBottleDetails}
                  handlefilterOnWinerySelect={handlefilterOnWinerySelect}
                  wineryList={wineryList}
                  handleCountryChange={handleCountryChange}
                  allCountry={allCountry}
                  regionList={regionList}
                  appellationWithRegion={appellationWithRegion}
                  colorList={colorList}
                  sweetNessList={sweetNessList}
                  stillList={stillList}
                  sparklingList={sparklingList}
                  handleSizeChange={handleSizeChange}
                  sizeList={sizeList}
                />
              </Col>
            )}
            <Col xl={10} className={cn('crtbtl-right', { hidden: isTranslationMode })}>
              <div className='inner'>
                <div className='Header'>
                  <h3 className='common-sub-heading'>Bottle Preview</h3>
                </div>
                <CardIframe
                  data={{
                    bottle_data: { ...bottleDetail },
                    users: { creator_meta: { country_code: bottleDetail.countrycode } },
                  }}
                />
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </MainLayout>
  )
}

EditBottle.propTypes = {}

export default EditBottle
